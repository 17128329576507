









































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import FlagshipProfilingYouAlreadyHaveYourContractedProductViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-you-already-have-your-contracted-product-view-model';

@Component({
  name: 'FlagshipProfilingYouAlreadyHaveYourContractedProduct',
  components: {
    FlagshipGoalsModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsModal.vue'),
    FlagshipGoalsWizardModal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsWizardModal.vue'),
  },
})
export default class FlagshipProfilingYouAlreadyHaveYourContractedProduct extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  contracted_product_view_model = Vue.observable(
    new FlagshipProfilingYouAlreadyHaveYourContractedProductViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
    this.$emit('hideRecommended');
  }

  defineMyGoals() {
    this.synced_is_open = false;
    this.$emit('hideRecommended');
    this.$emit('createGoals');
    this.contracted_product_view_model.defineMyGoals();
  }
}
